import '../styles/index.scss';

var tablet_size = 768;
var tablet_native_size = 375;
var desktop_size = 1400;

$(()=>{
	window.scale = 1;
	
	let handleResize = ()=>{
		let prHeight = $(".page-wrap").height() * (1/window.scale) * window.scale;
		
		if ($(window).width() > tablet_size)
		{
			if (false && $(window).width() >= desktop_size)
			{
			
				$(window).attr("style", "");
				$(".page-wrap").attr("style","");
				$(".page-wrap-outter").attr("style","");
				
				window.scale = 1;
			}
			else if ($(window).width() > tablet_size)
			{
				window.scale = $(window).width()/desktop_size;
				
				$(".page-wrap").attr("style", "transform: scale("+scale+");");
				$(".page-wrap-outter").attr("style","height: "+(prHeight*scale)+"px");
			}
		
			if (window.mode != "desktop")
			{
				$(window).attr("style", "");
				$(".page-wrap").attr("style","");
				$(".page-wrap-outter").attr("style","");
				
				window.scale = 1;
				
				window.mode = "desktop";
				setTimeout(()=>{ handleResize(); }, 500);
			}
		}
		else
		{
			window.scale = $(window).width()/tablet_native_size;
			
			$(".page-wrap").attr("style", "transform: scale("+scale+")");
			$(".page-wrap-outter").attr("style","height: "+(prHeight*scale)+"px");
			
			if (window.mode != "mobile")
			{
				window.mode = "mobile";
				
				$(window).attr("style", "");
				$(".page-wrap").attr("style","");
				$(".page-wrap-outter").attr("style","");
				
				window.scale = 1;
				setTimeout(()=>{ handleResize(); }, 500);
			}
		}
	};
	
	$(window).on("resize",()=>{ handleResize(); });
	$(window).on("load",()=>{ handleResize(); });
	
	handleResize();
	
	setTimeout(()=>{ 
		handleResize(); 
		$("body").attr("style","");
	}, 500);
	
});